import React from 'react';
import SEO from '../../components/seo';
import WaleriaEN from '../../components/desktop/Waleria/WaleriaEN';

const Artist = () => {
  return (
    <>
      <SEO title={'The Waleria Villa'} />
      <WaleriaEN />
    </>
  );
};

export default Artist;
