import React from 'react';
import {
  ColorWrapper,
  Container,
  ImageMove,
  PageContainer,
  TextSpaceMargin,
} from './styles';
import {SectionText, SectionTitle} from '../../atoms';
import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import img5 from './images/5.jpg';
import img6 from './images/6.jpg';
import img7 from './images/7.jpg';
import img8 from './images/8.jpg';
import img9 from './images/9.jpg';
import img10 from './images/10.jpg';
import img11 from './images/11.jpg';
import img12 from './images/12.jpg';
import img13 from './images/13.jpg';
import img14 from './images/14.jpg';
import img15 from './images/15.jpg';
import img16 from './images/16.jpg';
import img17 from './images/17.jpg';
import img18 from './images/18.jpg';
import img19 from './images/19.jpg';
import img20 from './images/20.jpg';
import img21 from './images/21.jpg';
import img22 from './images/22.jpg';
import img23 from './images/23.jpg';
import img24 from './images/24.jpg';
import img25 from './images/25.jpg';
import img26 from './images/26.jpg';
import img27 from './images/27.jpg';
import img28 from './images/28.jpg';
import img29 from './images/29.jpg';
import img30 from './images/30.jpg';
import img31 from './images/31.jpg';
import img32 from './images/32.jpg';
import img33 from './images/33.jpg';
import img34 from './images/34.jpg';
import img35 from './images/35.jpg';
import img36 from './images/36.jpg';
import img37 from './images/37.jpg';
import img38 from './images/38.jpg';
import img39 from './images/39.jpg';
import img40 from './images/40.jpg';
import img41 from './images/koszuta/053_anagl_altana_2.jpg';
import img42 from './images/koszuta/057_nagl_widok_z_okna_walerii3.jpg';
import img43 from './images/koszuta/058_anagl_bazarek3.jpg';
import img44 from './images/koszuta/091_waleria_anagl.jpg';
import img45 from './images/koszuta/092_waleria_anagl.jpg';
import img46 from './images/koszuta/093_waleria_aleja_anagl.jpg';
import img47 from './images/koszuta/094_piotra_skargi_anagl.jpg';
import img48 from './images/koszuta/095_zakopianka_anagl.jpg';
import img49 from './images/koszuta/097_muszka_anagl.jpg';
import img50 from './images/koszuta/097_stacja_anagl.jpg';
import img51 from './images/koszuta/098_altana_anagl.jpg';
import img52 from './images/koszuta/099_chrzan_anagl.jpg';
import moving from './images/waleria_01.png';
import Gallery from '../Gallery/Gallery';
import PlayerGrey from '../../atoms/PlayerGrey/PlayerGrey';

const Waleria = () => {
  return (
    <PageContainer backgroundColor={'#afa3c0'} style={{paddingBottom: 0}}>
      <Container>
        <ImageMove src={moving} />
        <TextSpaceMargin>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <SectionTitle color={'#525252'}>THE WALERIA VILLA </SectionTitle>
            <PlayerGrey src={require('../../../audio/WILLA WALERIA.mp3')} />
          </div>
          <SectionText color={'#525252'} style={{paddingTop: '1.5rem'}}>
            &emsp;&emsp;The Waleria Villa is the work of Rufin Morozowicz, who
            was the investor and architectural supervisor of the whole project.
            The Villa was erected in 1910 for his wife Waleria from the Kotowicz
            family, after whom the villa was named. The two-story building
            stands on several plots of land, emerging from the division of the
            Milanówek Las-Górny area. The property was located between today’s
            Spacerowa, Krasińskiego, Wojska Polskiego and Mickiewicza streets.
            The construction works were carried out by Rudzki from Grodzisk
            Mazowiecki. The building survived in its original two-story form
            until 1915. The last floor burned down at that time due to the
            explosion of a shrapnel. The reconstruction in 1920 was again
            undertaken by Rufin Morozowicz, but this time building a one-story
            construction. After the death of Rufin and Waleria Morozowicz, in
            1931 the Villa became the property of Maria Morozowicz-Szczepkowska,
            their daughter.
            <br />
            <br />
            &emsp;&emsp;Waleria was an open house. It is impossible to count how
            many guests in total visited the villa over the years.
            <br />
            <br />
            &emsp;&emsp;The part of the ground-floor, from the side of
            Krasińskiego Street, was allocated as the studio of Jan
            Szczepkowski. One floor higher, a large dining room was located,
            representing the project of the interior design for Royal Castle in
            Warsaw with its style of decor and furnishings.
            <br />
            <br />
            &emsp;&emsp;The garden of the Villa looked exceptional. According to
            Morozowicz's design, alleys, gazebos, orangeries and greenhouses
            were marked out throughout the property. The area was decorated with
            sculptures and chapels, surrounded by trees and shrubs. The whole
            building was fenced with a white wooden fence.
            <br />
            <br />
            &emsp;&emsp;Since 1982, the building has been entered in the
            register of monuments of Warsaw and later Masovian voivodeships. At
            the turn of the 1990s and 2000s, the Public High School in Milanówek
            used some of its rooms.
            <br />
            <br />
            &emsp;&emsp;The villa is built in the style of modernized
            historicism. You can see elements of the Polish court in it. It is
            built on an irregular plan, similar to a rectangle. The façade is
            decorated with a risalit and the porch is surrounded by columns.
            Currently, it is a one-story brick building with a mansard, covered
            with tiles. There were glazed verandas on the wings of the building.
            <br />
            <br />
            &emsp;&emsp;The building was purchased in 2007 by the Commune of
            Milanówek. In 2020, the town managed to obtain two grants to restore
            the building to its former glory. Thanks to almost 8.5 million
            external funds, Waleria will soon become a place for local and
            supra-local activities related to culture and education. There will
            be a permanent exhibition of the artist's works and workshops in
            sculpture, ceramics, woodcarving and other artistic forms will be
            held in the restored studio. Waleria will become a place of
            concerts, lectures and meetings, finally fulfilling her true
            potential.
          </SectionText>
          <Container style={{marginTop: '2rem'}}>
            <p
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
              }}>
              Information sources:
              <br />
              <br />
              Chrudzimska-Uhera Katarzyna, O rzeźbiarzu (nieco) zapomnianym,
              Niepodległość i Pamięć 17/1 (31), 257-271, 2010
              <br />
              <br />
              Chrudzimska-Uhera Katarzyna, Prof. Jan Szczepkowski i jego dzieło
              (1878-1964), Milanówek Miejsce Magiczne pod red. Andrzeja Pettyna,
              Towarzystwo Miłośników Milanówka, 2005
              <br />
              <br />
              Chrudzimska-Uhera Katarzyna, Jan Szczepkowski, Życie i twórczość,
              Urząd Miejski w Milanówku, Milanówek 2008 r.
              <br />
              <br />
              Koszuta Mariusz, Spacer z Walerią po letnisku Milanówek,
              Wydawnictwo Impress, Warszawa 2010 r.
              <br />
              <br />
              Morozowicz – Szczepkowska Maria, Z lotu ptaka, Państwowy Instytut
              Wydawniczy, Warszawa, 1968
              <br />
              <br />
              Szczepkowski Jan, Wypukłe i wklęsłe. Wspomnienia, Wstęp,
              opracowanie i komentarz Katarzyna Chrudzimska - Uhera, Urząd
              Miasta Milanówka, Milanówek, 2010
            </p>
          </Container>
        </TextSpaceMargin>
      </Container>
      <ColorWrapper
        bgColor={'#525252'}
        style={{marginTop: '3rem', padding: '2rem 0'}}>
        <Container>
          <Gallery
            columnsNumber={4}
            isImgFit
            style={{gridGap: '3rem 1rem'}}
            hoverColor="rgba(176, 163, 193, 0.4)"
            colorSign="#fff"
            images={[
              {
                src: img1,
                sign: 'Aleja ogrodowa',
              },
              {
                src: img2,
                sign: 'Aleja ogrodowa',
              },
              {
                src: img3,
                sign: 'Aleja zimową porą',
              },
              {
                src: img4,
                sign: 'Alejka w ogrodzie willi Waleria',
              },
              {
                src: img5,
                sign: 'Altana ogrodu na sztucznie utworzonym podwyższeniu',
              },
              {
                src: img6,
                sign: 'Altanka ogrodowa',
              },
              {
                src: img7,
                sign: 'Brama wejściowa do Willi Waleria',
              },
              {
                src: img8,
                sign: 'Budowa ogrodzenia posesji Walerii',
              },
              {
                src: img9,
                sign:
                  'Kapliczka Matki Boskiej ustawiona u zbiegu obecnych ulic Wojska Polskiego i Krasińskiego. Obok stojąca Waleria Morozowicz.',
              },
              {
                src: img10,
                sign: 'Leśna aleja spacerowa z altanami w tle',
              },
              {
                src: img11,
                sign: 'Leśny zakątek ogrody Willi Waleria',
              },
              {
                src: img12,
                sign: 'Ogrodzenie posesji',
              },
              {
                src: img13,
                sign: 'Ogrodzenie willi Waleria',
              },
              {
                src: img14,
                sign: 'Ogrodzenie willi Waleria',
              },

              {
                src: img15,
                sign: 'Salon Marii i Jana w willi Waleria',
              },
              {
                src: img16,
                sign: 'Spacer po ogrodzie willi Walerii',
              },
              {
                src: img17,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img18,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img19,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img20,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img21,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img22,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img23,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img24,
                sign:
                  'Widoczna w tle Waleria Morozowicz wśród materiałów budowlanych',
              },
              {
                src: img25,
                sign: 'Widoczne białe ogrodzenie posesji willi Waleria',
              },
              {
                src: img26,
                sign:
                  'Widok na Chrzanów Mały z okolic bramy zachodniej, przy obecnej ul. Wojska Polskiego',
              },
              {
                src: img27,
                sign: 'Widok na szklarnie oraz wille w trakcie budowy',
              },
              {
                src: img28,
                sign: 'Widok na ulicę Krasińskiego',
              },
              {
                src: img29,
                sign: 'Widok ogrodu w kierunku ulicy Krasińskiego',
              },
              {
                src: img30,
                sign: 'Widok z okna na ogród od strony zachodniej',
              },
              {
                src: img31,
                sign: 'Widok zachodniej części willi Waleria podczas budowy',
              },
              {
                src: img32,
                sign: 'Willa Waleria w całej okazałości',
              },
              {
                src: img33,
                sign: 'Willa Waleria, widok od ulicy Spacerowej',
              },
              {
                src: img34,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówk. Widok od strony południowej.',
              },
              {
                src: img35,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Element architektoniczny.',
              },
              {
                src: img36,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Schody prowadzące do wejścia. Obecnie nieistniejąće.',
              },
              {
                src: img37,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Taras nad wejściem.',
              },
              {
                src: img38,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Wejście od ul. Spacerowej.',
              },
              {
                src: img39,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Widok na dawną fontannę.',
              },
              {
                src: img40,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Widok na wejście od strony ul. Spacerowej.',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#525252'}>
        <Container>
          <SectionText style={{textAlign: 'center', color: '#ffffff'}}>
            Fotografie anaglifowe z albumu "Spacer z Walerią" autorstwa Mariusza
            Koszuty.
          </SectionText>
          <Gallery
            columnsNumber={4}
            isImgFit
            hoverColor="rgba(176, 163, 193, 0.4)"
            colorSign="#525252"
            images={[
              {
                src: img41,
                sign: '',
              },
              {
                src: img42,
                sign: '',
              },
              {
                src: img43,
                sign: '',
              },
              {
                src: img44,
                sign: '',
              },
              {
                src: img45,
                sign: '',
              },
              {
                src: img46,
                sign: '',
              },
              {
                src: img47,
                sign: '',
              },
              {
                src: img48,
                sign: '',
              },
              {
                src: img49,
                sign: '',
              },
              {
                src: img50,
                sign: '',
              },
              {
                src: img51,
                sign: '',
              },
              {
                src: img52,
                sign: '',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
    </PageContainer>
  );
};

export default Waleria;
